const counties = [
  "Abbeville County",
  "Acadia Parish",
  "Accomack County",
  "Ada County",
  "Adair County",
  "Adams County",
  "Addison County",
  "Aiken County",
  "Aitkin County",
  "Alachua County",
  "Alamance County",
  "Alameda County",
  "Alamosa County",
  "Albany County",
  "Albemarle County",
  "Alcona County",
  "Alcorn County",
  "Aleutians East Borough",
  "Aleutians West Census Area",
  "Alexander County",
  "Alexandria",
  "Alfalfa County",
  "Alger County",
  "Allamakee County",
  "Allegan County",
  "Allegany County",
  "Alleghany County",
  "Allegheny County",
  "Allen County",
  "Allen Parish",
  "Allendale County",
  "Alpena County",
  "Alpine County",
  "Amador County",
  "Amelia County",
  "Amherst County",
  "Amite County",
  "Anchorage Municipality",
  "Anchorage",
  "Anderson County",
  "Andrew County",
  "Andrews County",
  "Androscoggin County",
  "Angelina County",
  "Anne Arundel County",
  "Anoka County",
  "Anson County",
  "Antelope County",
  "Antrim County",
  "Apache County",
  "Appanoose County",
  "Appling County",
  "Appomattox County",
  "Aransas County",
  "Arapahoe County",
  "Archer County",
  "Archuleta County",
  "Arenac County",
  "Arkansas County",
  "Arlington County",
  "Armstrong County",
  "Aroostook County",
  "Arthur County",
  "Ascension Parish",
  "Ashe County",
  "Ashland County",
  "Ashley County",
  "Ashtabula County",
  "Asotin County",
  "Assumption Parish",
  "Atascosa County",
  "Atchison County",
  "Athens County",
  "Atkinson County",
  "Atlantic County",
  "Atoka County",
  "Attala County",
  "Audrain County",
  "Audubon County",
  "Auglaize County",
  "Augusta County",
  "Aurora County",
  "Austin County",
  "Autauga County",
  "Avery County",
  "Avoyelles Parish",
  "Baca County",
  "Bacon County",
  "Bailey County",
  "Baker County",
  "Baldwin County",
  "Ballard County",
  "Baltimore County",
  "Baltimore",
  "Bamberg County",
  "Bandera County",
  "Banks County",
  "Banner County",
  "Bannock County",
  "Baraga County",
  "Barber County",
  "Barbour County",
  "Barnes County",
  "Barnstable County",
  "Barnwell County",
  "Barren County",
  "Barron County",
  "Barrow County",
  "Barry County",
  "Bartholomew County",
  "Barton County",
  "Bartow County",
  "Bastrop County",
  "Bates County",
  "Bath County",
  "Baxter County",
  "Bay County",
  "Bayfield County",
  "Baylor County",
  "Beadle County",
  "Bear Lake County",
  "Beaufort County",
  "Beauregard Parish",
  "Beaver County",
  "Beaverhead County",
  "Becker County",
  "Beckham County",
  "Bedford County",
  "Bee County",
  "Belknap County",
  "Bell County",
  "Belmont County",
  "Beltrami County",
  "Ben Hill County",
  "Benewah County",
  "Bennett County",
  "Bennington County",
  "Benson County",
  "Bent County",
  "Benton County",
  "Benzie County",
  "Bergen County",
  "Berkeley County",
  "Berks County",
  "Berkshire County",
  "Bernalillo County",
  "Berrien County",
  "Bertie County",
  "Bethel Census Area",
  "Bexar County",
  "Bibb County",
  "Bienville Parish",
  "Big Horn County",
  "Big Stone County",
  "Billings County",
  "Bingham County",
  "Black Hawk County",
  "Blackford County",
  "Bladen County",
  "Blaine County",
  "Blair County",
  "Blanco County",
  "Bland County",
  "Bleckley County",
  "Bledsoe County",
  "Blount County",
  "Blue Earth County",
  "Boise County",
  "Bolivar County",
  "Bollinger County",
  "Bon Homme County",
  "Bond County",
  "Bonner County",
  "Bonneville County",
  "Boone County",
  "Borden County",
  "Bosque County",
  "Bossier Parish",
  "Botetourt County",
  "Bottineau County",
  "Boulder County",
  "Boundary County",
  "Bourbon County",
  "Bowie County",
  "Bowman County",
  "Box Butte County",
  "Box Elder County",
  "Boyd County",
  "Boyle County",
  "Bracken County",
  "Bradford County",
  "Bradley County",
  "Branch County",
  "Brantley County",
  "Braxton County",
  "Brazoria County",
  "Brazos County",
  "Breathitt County",
  "Breckinridge County",
  "Bremer County",
  "Brevard County",
  "Brewster County",
  "Briscoe County",
  "Bristol Bay Borough",
  "Bristol County",
  "Bristol",
  "Broadwater County",
  "Brooke County",
  "Brookings County",
  "Brooklyn",
  "Brooks County",
  "Broome County",
  "Broomfield",
  "Broward County",
  "Brown County",
  "Brule County",
  "Brunswick County",
  "Bryan County",
  "Buchanan County",
  "Buckingham County",
  "Bucks County",
  "Buena Vista County",
  "Buena Vista",
  "Buffalo County",
  "Bullitt County",
  "Bulloch County",
  "Bullock County",
  "Buncombe County",
  "Bureau County",
  "Burke County",
  "Burleigh County",
  "Burleson County",
  "Burlington County",
  "Burnet County",
  "Burnett County",
  "Burt County",
  "Butler County",
  "Butte County",
  "Butts County",
  "Cabarrus County",
  "Cabell County",
  "Cache County",
  "Caddo County",
  "Caddo Parish",
  "Calaveras County",
  "Calcasieu Parish",
  "Caldwell County",
  "Caldwell Parish",
  "Caledonia County",
  "Calhoun County",
  "Callahan County",
  "Callaway County",
  "Calloway County",
  "Calumet County",
  "Calvert County",
  "Camas County",
  "Cambria County",
  "Camden County",
  "Cameron County",
  "Cameron Parish",
  "Camp County",
  "Campbell County",
  "Canadian County",
  "Candler County",
  "Cannon County",
  "Canyon County",
  "Cape Girardeau County",
  "Cape May County",
  "Carbon County",
  "Caribou County",
  "Carlisle County",
  "Carlton County",
  "Caroline County",
  "Carroll County",
  "Carson City",
  "Carson County",
  "Carter County",
  "Carteret County",
  "Carver County",
  "Cascade County",
  "Casey County",
  "Cass County",
  "Cassia County",
  "Castro County",
  "Caswell County",
  "Catahoula Parish",
  "Catawba County",
  "Catoosa County",
  "Catron County",
  "Cattaraugus County",
  "Cavalier County",
  "Cayuga County",
  "Cecil County",
  "Cedar County",
  "Centre County",
  "Cerro Gordo County",
  "Chaffee County",
  "Chambers County",
  "Champaign County",
  "Chariton County",
  "Charles City County",
  "Charles County",
  "Charles Mix County",
  "Charleston County",
  "Charlevoix County",
  "Charlotte County",
  "Charlottesville",
  "Charlton County",
  "Chase County",
  "Chatham County",
  "Chattahoochee County",
  "Chattooga County",
  "Chautauqua County",
  "Chaves County",
  "Cheatham County",
  "Cheboygan County",
  "Chelan County",
  "Chemung County",
  "Chenango County",
  "Cherokee County",
  "Cherry County",
  "Chesapeake",
  "Cheshire County",
  "Chester County",
  "Chesterfield County",
  "Cheyenne County",
  "Chickasaw County",
  "Chicot County",
  "Childress County",
  "Chilton County",
  "Chippewa County",
  "Chisago County",
  "Chittenden County",
  "Choctaw County",
  "Chouteau County",
  "Chowan County",
  "Christian County",
  "Chugach Census Area",
  "Churchill County",
  "Cibola County",
  "Cimarron County",
  "Citrus County",
  "Clackamas County",
  "Claiborne County",
  "Claiborne Parish",
  "Clallam County",
  "Clare County",
  "Clarendon County",
  "Clarion County",
  "Clark County",
  "Clarke County",
  "Clatsop County",
  "Clay County",
  "Clayton County",
  "Clear Creek County",
  "Clearfield County",
  "Clearwater County",
  "Cleburne County",
  "Clermont County",
  "Cleveland County",
  "Clinch County",
  "Clinton County",
  "Cloud County",
  "Coahoma County",
  "Coal County",
  "Cobb County",
  "Cochise County",
  "Cochran County",
  "Cocke County",
  "Coconino County",
  "Codington County",
  "Coffee County",
  "Coffey County",
  "Coke County",
  "Colbert County",
  "Cole County",
  "Coleman County",
  "Coles County",
  "Colfax County",
  "Colleton County",
  "Collier County",
  "Collin County",
  "Collingsworth County",
  "Colonial Heights",
  "Colorado County",
  "Colquitt County",
  "Columbia County",
  "Columbiana County",
  "Columbus County",
  "Colusa County",
  "Comal County",
  "Comanche County",
  "Concho County",
  "Concordia Parish",
  "Conecuh County",
  "Conejos County",
  "Contra Costa County",
  "Converse County",
  "Conway County",
  "Cook County",
  "Cooke County",
  "Cooper County",
  "Coos County",
  "Coosa County",
  "Copiah County",
  "Copper River Census Area",
  "Corson County",
  "Cortland County",
  "Coryell County",
  "Coshocton County",
  "Costilla County",
  "Cottle County",
  "Cotton County",
  "Cottonwood County",
  "Covington County",
  "Covington",
  "Coweta County",
  "Cowley County",
  "Cowlitz County",
  "Coös County",
  "Craig County",
  "Craighead County",
  "Crane County",
  "Craven County",
  "Crawford County",
  "Creek County",
  "Crenshaw County",
  "Crisp County",
  "Crittenden County",
  "Crockett County",
  "Crook County",
  "Crosby County",
  "Cross County",
  "Crow Wing County",
  "Crowley County",
  "Culberson County",
  "Cullman County",
  "Culpeper County",
  "Cumberland County",
  "Cuming County",
  "Currituck County",
  "Curry County",
  "Custer County",
  "Cuyahoga County",
  "Dade County",
  "Daggett County",
  "Dakota County",
  "Dale County",
  "Dallam County",
  "Dallas County",
  "Dane County",
  "Daniels County",
  "Danville",
  "Dare County",
  "Darke County",
  "Darlington County",
  "Dauphin County",
  "Davidson County",
  "Davie County",
  "Daviess County",
  "Davis County",
  "Davison County",
  "Dawes County",
  "Dawson County",
  "Day County",
  "De Baca County",
  "DeKalb County",
  "DeSoto County",
  "DeSoto Parish",
  "DeWitt County",
  "Deaf Smith County",
  "Dearborn County",
  "Decatur County",
  "Deer Lodge County",
  "Defiance County",
  "Del Norte County",
  "Delaware County",
  "Delta County",
  "Denali Borough",
  "Dent County",
  "Denton County",
  "Denver",
  "Des Moines County",
  "Deschutes County",
  "Desha County",
  "Deuel County",
  "Dewey County",
  "Dickens County",
  "Dickenson County",
  "Dickey County",
  "Dickinson County",
  "Dickson County",
  "Dillingham Census Area",
  "Dillon County",
  "Dimmit County",
  "Dinwiddie County",
  "Divide County",
  "Dixie County",
  "Dixon County",
  "Doddridge County",
  "Dodge County",
  "Dolores County",
  "Doniphan County",
  "Donley County",
  "Dooly County",
  "Door County",
  "Dorchester County",
  "Dougherty County",
  "Douglas County",
  "Doña Ana County",
  "Drew County",
  "DuPage County",
  "Dubois County",
  "Dubuque County",
  "Duchesne County",
  "Dukes County",
  "Dundy County",
  "Dunklin County",
  "Dunn County",
  "Duplin County",
  "Durham County",
  "Dutchess County",
  "Duval County",
  "Dyer County",
  "Eagle County",
  "Early County",
  "East Baton Rouge Parish",
  "East Carroll Parish",
  "East Feliciana Parish",
  "Eastland County",
  "Eaton County",
  "Eau Claire County",
  "Echols County",
  "Ector County",
  "Eddy County",
  "Edgar County",
  "Edgecombe County",
  "Edgefield County",
  "Edmonson County",
  "Edmunds County",
  "Edwards County",
  "Effingham County",
  "El Dorado County",
  "El Paso County",
  "Elbert County",
  "Elk County",
  "Elkhart County",
  "Elko County",
  "Elliott County",
  "Ellis County",
  "Ellsworth County",
  "Elmore County",
  "Emanuel County",
  "Emery County",
  "Emmet County",
  "Emmons County",
  "Emporia",
  "Erath County",
  "Erie County",
  "Escambia County",
  "Esmeralda County",
  "Essex County",
  "Estill County",
  "Etowah County",
  "Eureka County",
  "Evangeline Parish",
  "Evans County",
  "Fairbanks North Star Borough",
  "Fairfax County",
  "Fairfax",
  "Fairfield County",
  "Fall River County",
  "Fallon County",
  "Falls Church",
  "Falls County",
  "Fannin County",
  "Faribault County",
  "Faulk County",
  "Faulkner County",
  "Fauquier County",
  "Fayette County",
  "Fentress County",
  "Fergus County",
  "Ferry County",
  "Fillmore County",
  "Finney County",
  "Fisher County",
  "Flagler County",
  "Flathead County",
  "Fleming County",
  "Florence County",
  "Floyd County",
  "Fluvanna County",
  "Foard County",
  "Fond du Lac County",
  "Ford County",
  "Forest County",
  "Forrest County",
  "Forsyth County",
  "Fort Bend County",
  "Foster County",
  "Fountain County",
  "Franklin County",
  "Franklin Parish",
  "Franklin",
  "Frederick County",
  "Fredericksburg",
  "Freeborn County",
  "Freestone County",
  "Fremont County",
  "Fresno County",
  "Frio County",
  "Frontier County",
  "Fulton County",
  "Furnas County",
  "Gadsden County",
  "Gage County",
  "Gaines County",
  "Galax",
  "Gallatin County",
  "Gallia County",
  "Galveston County",
  "Garden County",
  "Garfield County",
  "Garland County",
  "Garrard County",
  "Garrett County",
  "Garvin County",
  "Garza County",
  "Gasconade County",
  "Gaston County",
  "Gates County",
  "Geary County",
  "Geauga County",
  "Gem County",
  "Genesee County",
  "Geneva County",
  "Gentry County",
  "George County",
  "Georgetown County",
  "Gibson County",
  "Gila County",
  "Gilchrist County",
  "Giles County",
  "Gillespie County",
  "Gilliam County",
  "Gilmer County",
  "Gilpin County",
  "Glacier County",
  "Glades County",
  "Gladwin County",
  "Glascock County",
  "Glasscock County",
  "Glenn County",
  "Gloucester County",
  "Glynn County",
  "Gogebic County",
  "Golden Valley County",
  "Goliad County",
  "Gonzales County",
  "Goochland County",
  "Goodhue County",
  "Gooding County",
  "Gordon County",
  "Goshen County",
  "Gosper County",
  "Gove County",
  "Grady County",
  "Grafton County",
  "Graham County",
  "Grainger County",
  "Grand County",
  "Grand Forks County",
  "Grand Isle County",
  "Grand Traverse County",
  "Granite County",
  "Grant County",
  "Grant Parish",
  "Granville County",
  "Gratiot County",
  "Graves County",
  "Gray County",
  "Grays Harbor County",
  "Grayson County",
  "Greeley County",
  "Green County",
  "Green Lake County",
  "Greenbrier County",
  "Greene County",
  "Greenlee County",
  "Greensville County",
  "Greenup County",
  "Greenville County",
  "Greenwood County",
  "Greer County",
  "Gregg County",
  "Gregory County",
  "Grenada County",
  "Griggs County",
  "Grimes County",
  "Grundy County",
  "Guadalupe County",
  "Guernsey County",
  "Guilford County",
  "Gulf County",
  "Gunnison County",
  "Guthrie County",
  "Gwinnett County",
  "Haakon County",
  "Habersham County",
  "Haines Borough",
  "Hale County",
  "Halifax County",
  "Hall County",
  "Hamblen County",
  "Hamilton County",
  "Hamlin County",
  "Hampden County",
  "Hampshire County",
  "Hampton County",
  "Hampton",
  "Hancock County",
  "Hand County",
  "Hanover County",
  "Hansford County",
  "Hanson County",
  "Haralson County",
  "Hardee County",
  "Hardeman County",
  "Hardin County",
  "Harding County",
  "Hardy County",
  "Harford County",
  "Harlan County",
  "Harmon County",
  "Harnett County",
  "Harney County",
  "Harper County",
  "Harris County",
  "Harrison County",
  "Harrisonburg",
  "Hart County",
  "Hartford County",
  "Hartley County",
  "Harvey County",
  "Haskell County",
  "Hawaii County",
  "Hawkins County",
  "Hayes County",
  "Hays County",
  "Haywood County",
  "Heard County",
  "Hemphill County",
  "Hempstead County",
  "Henderson County",
  "Hendricks County",
  "Hendry County",
  "Hennepin County",
  "Henrico County",
  "Henry County",
  "Herkimer County",
  "Hernando County",
  "Hertford County",
  "Hettinger County",
  "Hickman County",
  "Hickory County",
  "Hidalgo County",
  "Highland County",
  "Highlands County",
  "Hill County",
  "Hillsborough County",
  "Hillsdale County",
  "Hinds County",
  "Hinsdale County",
  "Hitchcock County",
  "Hocking County",
  "Hockley County",
  "Hodgeman County",
  "Hoke County",
  "Holmes County",
  "Holt County",
  "Honolulu County",
  "Hood County",
  "Hood River County",
  "Hooker County",
  "Hoonah–Angoon Census Area",
  "Hopewell",
  "Hopkins County",
  "Horry County",
  "Hot Spring County",
  "Hot Springs County",
  "Houghton County",
  "Houston County",
  "Howard County",
  "Howell County",
  "Hubbard County",
  "Hudson County",
  "Hudspeth County",
  "Huerfano County",
  "Hughes County",
  "Humboldt County",
  "Humphreys County",
  "Hunt County",
  "Hunterdon County",
  "Huntingdon County",
  "Huntington County",
  "Huron County",
  "Hutchinson County",
  "Hyde County",
  "Iberia Parish",
  "Iberville Parish",
  "Ida County",
  "Idaho County",
  "Imperial County",
  "Independence County",
  "Indian River County",
  "Indiana County",
  "Ingham County",
  "Inyo County",
  "Ionia County",
  "Iosco County",
  "Iowa County",
  "Iredell County",
  "Irion County",
  "Iron County",
  "Iroquois County",
  "Irwin County",
  "Isabella County",
  "Isanti County",
  "Island County",
  "Isle of Wight County",
  "Issaquena County",
  "Itasca County",
  "Itawamba County",
  "Izard County",
  "Jack County",
  "Jackson County",
  "Jackson Parish",
  "James City County",
  "Jasper County",
  "Jay County",
  "Jeff Davis County",
  "Jefferson County",
  "Jefferson Davis County",
  "Jefferson Davis Parish",
  "Jefferson Parish",
  "Jenkins County",
  "Jennings County",
  "Jerauld County",
  "Jerome County",
  "Jersey County",
  "Jessamine County",
  "Jewell County",
  "Jim Hogg County",
  "Jim Wells County",
  "Jo Daviess County",
  "Johnson County",
  "Johnston County",
  "Jones County",
  "Josephine County",
  "Juab County",
  "Judith Basin County",
  "Juneau County",
  "Juneau",
  "Juniata County",
  "Kalamazoo County",
  "Kalawao County",
  "Kalkaska County",
  "Kanabec County",
  "Kanawha County",
  "Kandiyohi County",
  "Kane County",
  "Kankakee County",
  "Karnes County",
  "Kauai County",
  "Kaufman County",
  "Kay County",
  "Kearney County",
  "Kearny County",
  "Keith County",
  "Kemper County",
  "Kenai Peninsula Borough",
  "Kendall County",
  "Kenedy County",
  "Kennebec County",
  "Kenosha County",
  "Kent County",
  "Kenton County",
  "Keokuk County",
  "Kern County",
  "Kerr County",
  "Kershaw County",
  "Ketchikan Gateway Borough",
  "Kewaunee County",
  "Keweenaw County",
  "Keya Paha County",
  "Kidder County",
  "Kimball County",
  "Kimble County",
  "King County",
  "King George County",
  "King William County",
  "King and Queen County",
  "Kingfisher County",
  "Kingman County",
  "Kings County",
  "Kingsbury County",
  "Kinney County",
  "Kiowa County",
  "Kit Carson County",
  "Kitsap County",
  "Kittitas County",
  "Kittson County",
  "Klamath County",
  "Kleberg County",
  "Klickitat County",
  "Knott County",
  "Knox County",
  "Kodiak Island Borough",
  "Koochiching County",
  "Kootenai County",
  "Kosciusko County",
  "Kossuth County",
  "Kusilvak Census Area",
  "La Crosse County",
  "La Paz County",
  "La Plata County",
  "La Salle County",
  "La Salle Parish",
  "LaGrange County",
  "LaMoure County",
  "LaPorte County",
  "LaRue County",
  "LaSalle County",
  "Labette County",
  "Lac qui Parle County",
  "Lackawanna County",
  "Laclede County",
  "Lafayette County",
  "Lafayette Parish",
  "Lafourche Parish",
  "Lake County",
  "Lake and Peninsula Borough",
  "Lake of the Woods County",
  "Lamar County",
  "Lamb County",
  "Lamoille County",
  "Lampasas County",
  "Lancaster County",
  "Lander County",
  "Lane County",
  "Langlade County",
  "Lanier County",
  "Lapeer County",
  "Laramie County",
  "Larimer County",
  "Las Animas County",
  "Lassen County",
  "Latah County",
  "Latimer County",
  "Lauderdale County",
  "Laurel County",
  "Laurens County",
  "Lavaca County",
  "Lawrence County",
  "Le Flore County",
  "Le Sueur County",
  "Lea County",
  "Leake County",
  "Leavenworth County",
  "Lebanon County",
  "Lee County",
  "Leelanau County",
  "Leflore County",
  "Lehigh County",
  "Lemhi County",
  "Lenawee County",
  "Lenoir County",
  "Leon County",
  "Leslie County",
  "Letcher County",
  "Levy County",
  "Lewis County",
  "Lewis and Clark County",
  "Lexington County",
  "Lexington",
  "Liberty County",
  "Licking County",
  "Limestone County",
  "Lincoln County",
  "Lincoln Parish",
  "Linn County",
  "Lipscomb County",
  "Litchfield County",
  "Little River County",
  "Live Oak County",
  "Livingston County",
  "Livingston Parish",
  "Llano County",
  "Logan County",
  "Long County",
  "Lonoke County",
  "Lorain County",
  "Los Alamos County",
  "Los Angeles County",
  "Loudon County",
  "Loudoun County",
  "Louisa County",
  "Loup County",
  "Love County",
  "Loving County",
  "Lowndes County",
  "Lubbock County",
  "Lucas County",
  "Luce County",
  "Lumpkin County",
  "Luna County",
  "Lunenburg County",
  "Luzerne County",
  "Lycoming County",
  "Lyman County",
  "Lynchburg",
  "Lynn County",
  "Lyon County",
  "Mackinac County",
  "Macomb County",
  "Macon County",
  "Macoupin County",
  "Madera County",
  "Madison County",
  "Madison Parish",
  "Magoffin County",
  "Mahaska County",
  "Mahnomen County",
  "Mahoning County",
  "Major County",
  "Malheur County",
  "Manassas Park",
  "Manassas",
  "Manatee County",
  "Manhattan",
  "Manistee County",
  "Manitowoc County",
  "Marathon County",
  "Marengo County",
  "Maricopa County",
  "Maries County",
  "Marin County",
  "Marinette County",
  "Marion County",
  "Mariposa County",
  "Marlboro County",
  "Marquette County",
  "Marshall County",
  "Martin County",
  "Martinsville",
  "Mason County",
  "Massac County",
  "Matagorda County",
  "Matanuska-Susitna Borough",
  "Mathews County",
  "Maui County",
  "Maury County",
  "Maverick County",
  "Mayes County",
  "McClain County",
  "McCone County",
  "McCook County",
  "McCormick County",
  "McCracken County",
  "McCreary County",
  "McCulloch County",
  "McCurtain County",
  "McDonald County",
  "McDonough County",
  "McDowell County",
  "McDuffie County",
  "McHenry County",
  "McIntosh County",
  "McKean County",
  "McKenzie County",
  "McKinley County",
  "McLean County",
  "McLennan County",
  "McLeod County",
  "McMinn County",
  "McMullen County",
  "McNairy County",
  "McPherson County",
  "Meade County",
  "Meagher County",
  "Mecklenburg County",
  "Mecosta County",
  "Medina County",
  "Meeker County",
  "Meigs County",
  "Mellette County",
  "Menard County",
  "Mendocino County",
  "Menifee County",
  "Menominee County",
  "Merced County",
  "Mercer County",
  "Meriwether County",
  "Merrick County",
  "Merrimack County",
  "Mesa County",
  "Metcalfe County",
  "Miami County",
  "Miami-Dade County",
  "Middlesex County",
  "Midland County",
  "Mifflin County",
  "Milam County",
  "Millard County",
  "Mille Lacs County",
  "Miller County",
  "Mills County",
  "Milwaukee County",
  "Miner County",
  "Mineral County",
  "Mingo County",
  "Minidoka County",
  "Minnehaha County",
  "Missaukee County",
  "Mississippi County",
  "Missoula County",
  "Mitchell County",
  "Mobile County",
  "Modoc County",
  "Moffat County",
  "Mohave County",
  "Moniteau County",
  "Monmouth County",
  "Mono County",
  "Monona County",
  "Monongalia County",
  "Monroe County",
  "Montague County",
  "Montcalm County",
  "Monterey County",
  "Montezuma County",
  "Montgomery County",
  "Montmorency County",
  "Montour County",
  "Montrose County",
  "Moody County",
  "Moore County",
  "Mora County",
  "Morehouse Parish",
  "Morgan County",
  "Morrill County",
  "Morris County",
  "Morrison County",
  "Morrow County",
  "Morton County",
  "Motley County",
  "Moultrie County",
  "Mountrail County",
  "Mower County",
  "Muhlenberg County",
  "Multnomah County",
  "Murray County",
  "Muscatine County",
  "Muscogee County",
  "Muskegon County",
  "Muskingum County",
  "Muskogee County",
  "Musselshell County",
  "Nacogdoches County",
  "Nance County",
  "Nantucket County",
  "Napa County",
  "Nash County",
  "Nassau County",
  "Natchitoches Parish",
  "Natrona County",
  "Navajo County",
  "Navarro County",
  "Nelson County",
  "Nemaha County",
  "Neosho County",
  "Neshoba County",
  "Ness County",
  "Nevada County",
  "New Castle County",
  "New Hanover County",
  "New Haven County",
  "New Kent County",
  "New London County",
  "New Madrid County",
  "New York County",
  "Newaygo County",
  "Newberry County",
  "Newport County",
  "Newport News",
  "Newton County",
  "Nez Perce County",
  "Niagara County",
  "Nicholas County",
  "Nicollet County",
  "Niobrara County",
  "Noble County",
  "Nobles County",
  "Nodaway County",
  "Nolan County",
  "Nome Census Area",
  "Norfolk County",
  "Norfolk",
  "Norman County",
  "North Slope Borough",
  "Northampton County",
  "Northumberland County",
  "Northwest Arctic Borough",
  "Norton County",
  "Norton",
  "Nottoway County",
  "Nowata County",
  "Noxubee County",
  "Nuckolls County",
  "Nueces County",
  "Nye County",
  "O'Brien County",
  "Oakland County",
  "Obion County",
  "Ocean County",
  "Oceana County",
  "Ochiltree County",
  "Oconee County",
  "Oconto County",
  "Ogemaw County",
  "Oglala Lakota County",
  "Ogle County",
  "Oglethorpe County",
  "Ohio County",
  "Okaloosa County",
  "Okanogan County",
  "Okeechobee County",
  "Okfuskee County",
  "Oklahoma County",
  "Okmulgee County",
  "Oktibbeha County",
  "Oldham County",
  "Oliver County",
  "Olmsted County",
  "Oneida County",
  "Onondaga County",
  "Onslow County",
  "Ontario County",
  "Ontonagon County",
  "Orange County",
  "Orangeburg County",
  "Oregon County",
  "Orleans County",
  "Orleans Parish",
  "Osage County",
  "Osborne County",
  "Osceola County",
  "Oscoda County",
  "Oswego County",
  "Otero County",
  "Otoe County",
  "Otsego County",
  "Ottawa County",
  "Otter Tail County",
  "Ouachita County",
  "Ouachita Parish",
  "Ouray County",
  "Outagamie County",
  "Overton County",
  "Owen County",
  "Owsley County",
  "Owyhee County",
  "Oxford County",
  "Ozark County",
  "Ozaukee County",
  "Pacific County",
  "Page County",
  "Palm Beach County",
  "Palo Alto County",
  "Palo Pinto County",
  "Pamlico County",
  "Panola County",
  "Park County",
  "Parke County",
  "Parker County",
  "Parmer County",
  "Pasco County",
  "Pasquotank County",
  "Passaic County",
  "Patrick County",
  "Paulding County",
  "Pawnee County",
  "Payette County",
  "Payne County",
  "Peach County",
  "Pearl River County",
  "Pecos County",
  "Pembina County",
  "Pemiscot County",
  "Pend Oreille County",
  "Pender County",
  "Pendleton County",
  "Pennington County",
  "Penobscot County",
  "Peoria County",
  "Pepin County",
  "Perkins County",
  "Perquimans County",
  "Perry County",
  "Pershing County",
  "Person County",
  "Petersburg Borough",
  "Petersburg",
  "Petroleum County",
  "Pettis County",
  "Phelps County",
  "Philadelphia County",
  "Phillips County",
  "Piatt County",
  "Pickaway County",
  "Pickens County",
  "Pickett County",
  "Pierce County",
  "Pike County",
  "Pima County",
  "Pinal County",
  "Pine County",
  "Pinellas County",
  "Pipestone County",
  "Piscataquis County",
  "Pitkin County",
  "Pitt County",
  "Pittsburg County",
  "Pittsylvania County",
  "Piute County",
  "Placer County",
  "Plaquemines Parish",
  "Platte County",
  "Pleasants County",
  "Plumas County",
  "Plymouth County",
  "Pocahontas County",
  "Poinsett County",
  "Pointe Coupee Parish",
  "Polk County",
  "Pondera County",
  "Pontotoc County",
  "Pope County",
  "Poquoson",
  "Portage County",
  "Porter County",
  "Portsmouth",
  "Posey County",
  "Pottawatomie County",
  "Pottawattamie County",
  "Potter County",
  "Powder River County",
  "Powell County",
  "Power County",
  "Poweshiek County",
  "Powhatan County",
  "Prairie County",
  "Pratt County",
  "Preble County",
  "Prentiss County",
  "Presidio County",
  "Presque Isle County",
  "Preston County",
  "Price County",
  "Prince Edward County",
  "Prince George County",
  "Prince George's County",
  "Prince William County",
  "Prince of Wales–Hyder Census Area",
  "Providence County",
  "Prowers County",
  "Pueblo County",
  "Pulaski County",
  "Pushmataha County",
  "Putnam County",
  "Quay County",
  "Queen Anne's County",
  "Queens",
  "Quitman County",
  "Rabun County",
  "Racine County",
  "Radford",
  "Rains County",
  "Raleigh County",
  "Ralls County",
  "Ramsey County",
  "Randall County",
  "Randolph County",
  "Rankin County",
  "Ransom County",
  "Rapides Parish",
  "Rappahannock County",
  "Ravalli County",
  "Rawlins County",
  "Ray County",
  "Reagan County",
  "Real County",
  "Red Lake County",
  "Red River County",
  "Red River Parish",
  "Red Willow County",
  "Redwood County",
  "Reeves County",
  "Refugio County",
  "Reno County",
  "Rensselaer County",
  "Renville County",
  "Republic County",
  "Reynolds County",
  "Rhea County",
  "Rice County",
  "Rich County",
  "Richardson County",
  "Richland County",
  "Richland Parish",
  "Richmond County",
  "Richmond",
  "Riley County",
  "Ringgold County",
  "Rio Arriba County",
  "Rio Blanco County",
  "Rio Grande County",
  "Ripley County",
  "Ritchie County",
  "Riverside County",
  "Roane County",
  "Roanoke County",
  "Roanoke",
  "Roberts County",
  "Robertson County",
  "Robeson County",
  "Rock County",
  "Rock Island County",
  "Rockbridge County",
  "Rockcastle County",
  "Rockdale County",
  "Rockingham County",
  "Rockland County",
  "Rockwall County",
  "Roger Mills County",
  "Rogers County",
  "Rolette County",
  "Rooks County",
  "Roosevelt County",
  "Roscommon County",
  "Roseau County",
  "Rosebud County",
  "Ross County",
  "Routt County",
  "Rowan County",
  "Runnels County",
  "Rush County",
  "Rusk County",
  "Russell County",
  "Rutherford County",
  "Rutland County",
  "Sabine County",
  "Sabine Parish",
  "Sac County",
  "Sacramento County",
  "Sagadahoc County",
  "Saginaw County",
  "Saguache County",
  "Salem County",
  "Salem",
  "Saline County",
  "Salt Lake County",
  "Saluda County",
  "Sampson County",
  "San Augustine County",
  "San Benito County",
  "San Bernardino County",
  "San Diego County",
  "San Francisco County",
  "San Jacinto County",
  "San Joaquin County",
  "San Juan County",
  "San Luis Obispo County",
  "San Mateo County",
  "San Miguel County",
  "San Patricio County",
  "San Saba County",
  "Sanborn County",
  "Sanders County",
  "Sandoval County",
  "Sandusky County",
  "Sangamon County",
  "Sanilac County",
  "Sanpete County",
  "Santa Barbara County",
  "Santa Clara County",
  "Santa Cruz County",
  "Santa Fe County",
  "Santa Rosa County",
  "Sarasota County",
  "Saratoga County",
  "Sargent County",
  "Sarpy County",
  "Sauk County",
  "Saunders County",
  "Sawyer County",
  "Schenectady County",
  "Schleicher County",
  "Schley County",
  "Schoharie County",
  "Schoolcraft County",
  "Schuyler County",
  "Schuylkill County",
  "Scioto County",
  "Scotland County",
  "Scott County",
  "Scotts Bluff County",
  "Screven County",
  "Scurry County",
  "Searcy County",
  "Sebastian County",
  "Sedgwick County",
  "Seminole County",
  "Seneca County",
  "Sequatchie County",
  "Sequoyah County",
  "Sevier County",
  "Seward County",
  "Shackelford County",
  "Shannon County",
  "Sharkey County",
  "Sharp County",
  "Shasta County",
  "Shawano County",
  "Shawnee County",
  "Sheboygan County",
  "Shelby County",
  "Shenandoah County",
  "Sherburne County",
  "Sheridan County",
  "Sherman County",
  "Shiawassee County",
  "Shoshone County",
  "Sibley County",
  "Sierra County",
  "Silver Bow County",
  "Simpson County",
  "Sioux County",
  "Siskiyou County",
  "Sitka",
  "Skagit County",
  "Skamania County",
  "Slope County",
  "Smith County",
  "Smyth County",
  "Snohomish County",
  "Snyder County",
  "Socorro County",
  "Solano County",
  "Somerset County",
  "Somervell County",
  "Sonoma County",
  "Southampton County",
  "Southeast Fairbanks Census Area",
  "Spalding County",
  "Spartanburg County",
  "Spencer County",
  "Spink County",
  "Spokane County",
  "Spotsylvania County",
  "St. Bernard Parish",
  "St. Charles County",
  "St. Charles Parish",
  "St. Clair County",
  "St. Croix County",
  "St. Francis County",
  "St. Francois County",
  "St. Helena Parish",
  "St. James Parish",
  "St. John the Baptist Parish",
  "St. Johns County",
  "St. Joseph County",
  "St. Landry Parish",
  "St. Lawrence County",
  "St. Louis County",
  "St. Louis",
  "St. Lucie County",
  "St. Martin Parish",
  "St. Mary Parish",
  "St. Mary's County",
  "St. Tammany Parish",
  "Stafford County",
  "Stanislaus County",
  "Stanley County",
  "Stanly County",
  "Stanton County",
  "Stark County",
  "Starke County",
  "Starr County",
  "Staten Island",
  "Staunton",
  "Ste. Genevieve County",
  "Stearns County",
  "Steele County",
  "Stephens County",
  "Stephenson County",
  "Sterling County",
  "Steuben County",
  "Stevens County",
  "Stewart County",
  "Stillwater County",
  "Stoddard County",
  "Stokes County",
  "Stone County",
  "Stonewall County",
  "Storey County",
  "Story County",
  "Strafford County",
  "Stutsman County",
  "Sublette County",
  "Suffolk County",
  "Suffolk",
  "Sullivan County",
  "Sully County",
  "Summers County",
  "Summit County",
  "Sumner County",
  "Sumter County",
  "Sunflower County",
  "Surry County",
  "Susquehanna County",
  "Sussex County",
  "Sutter County",
  "Sutton County",
  "Suwannee County",
  "Swain County",
  "Sweet Grass County",
  "Sweetwater County",
  "Swift County",
  "Swisher County",
  "Switzerland County",
  "Talbot County",
  "Taliaferro County",
  "Talladega County",
  "Tallahatchie County",
  "Tallapoosa County",
  "Tama County",
  "Taney County",
  "Tangipahoa Parish",
  "Taos County",
  "Tarrant County",
  "Tate County",
  "Tattnall County",
  "Taylor County",
  "Tazewell County",
  "Tehama County",
  "Telfair County",
  "Teller County",
  "Tensas Parish",
  "Terrebonne Parish",
  "Terrell County",
  "Terry County",
  "Teton County",
  "Texas County",
  "Thayer County",
  "The Bronx",
  "Thomas County",
  "Throckmorton County",
  "Thurston County",
  "Tift County",
  "Tillamook County",
  "Tillman County",
  "Tioga County",
  "Tippah County",
  "Tippecanoe County",
  "Tipton County",
  "Tishomingo County",
  "Titus County",
  "Todd County",
  "Tolland County",
  "Tom Green County",
  "Tompkins County",
  "Tooele County",
  "Toole County",
  "Toombs County",
  "Torrance County",
  "Towner County",
  "Towns County",
  "Traill County",
  "Transylvania County",
  "Traverse County",
  "Travis County",
  "Treasure County",
  "Trego County",
  "Trempealeau County",
  "Treutlen County",
  "Trigg County",
  "Trimble County",
  "Trinity County",
  "Tripp County",
  "Troup County",
  "Trousdale County",
  "Trumbull County",
  "Tucker County",
  "Tulare County",
  "Tulsa County",
  "Tunica County",
  "Tuolumne County",
  "Turner County",
  "Tuscaloosa County",
  "Tuscarawas County",
  "Tuscola County",
  "Twiggs County",
  "Twin Falls County",
  "Tyler County",
  "Tyrrell County",
  "Uinta County",
  "Uintah County",
  "Ulster County",
  "Umatilla County",
  "Unicoi County",
  "Union County",
  "Union Parish",
  "Upshur County",
  "Upson County",
  "Upton County",
  "Utah County",
  "Uvalde County",
  "Val Verde County",
  "Valdez–Cordova Census Area",
  "Valencia County",
  "Valley County",
  "Van Buren County",
  "Van Wert County",
  "Van Zandt County",
  "Vance County",
  "Vanderburgh County",
  "Venango County",
  "Ventura County",
  "Vermilion County",
  "Vermilion Parish",
  "Vermillion County",
  "Vernon County",
  "Vernon Parish",
  "Victoria County",
  "Vigo County",
  "Vilas County",
  "Vinton County",
  "Virginia Beach",
  "Volusia County",
  "Wabash County",
  "Wabasha County",
  "Wabaunsee County",
  "Wadena County",
  "Wagoner County",
  "Wahkiakum County",
  "Wake County",
  "Wakulla County",
  "Waldo County",
  "Walker County",
  "Walla Walla County",
  "Wallace County",
  "Waller County",
  "Wallowa County",
  "Walsh County",
  "Walthall County",
  "Walton County",
  "Walworth County",
  "Wapello County",
  "Ward County",
  "Ware County",
  "Warren County",
  "Warrick County",
  "Wasatch County",
  "Wasco County",
  "Waseca County",
  "Washakie County",
  "Washburn County",
  "Washington County",
  "Washington Parish",
  "Washita County",
  "Washoe County",
  "Washtenaw County",
  "Watauga County",
  "Watonwan County",
  "Waukesha County",
  "Waupaca County",
  "Waushara County",
  "Wayne County",
  "Waynesboro",
  "Weakley County",
  "Webb County",
  "Weber County",
  "Webster County",
  "Webster Parish",
  "Weld County",
  "Wells County",
  "West Baton Rouge Parish",
  "West Carroll Parish",
  "West Feliciana Parish",
  "Westchester County",
  "Westmoreland County",
  "Weston County",
  "Wetzel County",
  "Wexford County",
  "Wharton County",
  "Whatcom County",
  "Wheatland County",
  "Wheeler County",
  "White County",
  "White Pine County",
  "Whiteside County",
  "Whitfield County",
  "Whitley County",
  "Whitman County",
  "Wibaux County",
  "Wichita County",
  "Wicomico County",
  "Wilbarger County",
  "Wilcox County",
  "Wilkes County",
  "Wilkin County",
  "Wilkinson County",
  "Will County",
  "Willacy County",
  "Williams County",
  "Williamsburg County",
  "Williamsburg",
  "Williamson County",
  "Wilson County",
  "Winchester",
  "Windham County",
  "Windsor County",
  "Winkler County",
  "Winn Parish",
  "Winnebago County",
  "Winneshiek County",
  "Winona County",
  "Winston County",
  "Wirt County",
  "Wise County",
  "Wolfe County",
  "Wood County",
  "Woodbury County",
  "Woodford County",
  "Woodruff County",
  "Woods County",
  "Woodson County",
  "Woodward County",
  "Worcester County",
  "Worth County",
  "Wright County",
  "Wyandot County",
  "Wyandotte County",
  "Wyoming County",
  "Wythe County",
  "Yadkin County",
  "Yakima County",
  "Yakutat",
  "Yalobusha County",
  "Yamhill County",
  "Yancey County",
  "Yankton County",
  "Yates County",
  "Yavapai County",
  "Yazoo County",
  "Yell County",
  "Yellow Medicine County",
  "Yellowstone County",
  "Yoakum County",
  "Yolo County",
  "York County",
  "Young County",
  "Yuba County",
  "Yukon–Koyukuk Census Area",
  "Yuma County",
  "Zapata County",
  "Zavala County",
  "Ziebach County",
]
export default counties
