import React, {useEffect, useState} from 'react'
import amplitude from 'amplitude-js'
import {
  Autocomplete,
  Box,
  Container,
  CssBaseline,
  Grid,
  Link,
  Stack,
  styled,
  TextField,
  Typography
} from '@mui/material'
import counties from "./counties";

interface CountyCaseDataResponse {
  cases_per_100k_7_day_count: string
  community_transmission_level: string
  county_name: string
  fips_code: string
  report_date: string
  state_name: string
}
const FormInputField = styled(TextField)(() => ({
  margin: '1rem 0',
  width: '100%',
}))
const amp = amplitude.getInstance()
amp.init('1bdbb8d49faf56de29e620d5ebb6953b','',{
  includeReferrer: true,
})
const registerSetter = (setter: Function) => (event : React.ChangeEvent<HTMLInputElement>) => setter(event.target.value)
function getTodayString() {
  const date = new Date()
  date.setDate(date.getDate()-1)
  return `${date.getFullYear()}-${(date.getMonth()+1+'').padStart(2, '0')}-${(date.getDate()+'').padStart(2, '0')}`
}
const storageKeyForCounty = 'county'
const chanceOfCovidInfectionWithBooster = (() => {
  let monthsSinceBooster = 1.0
  switch (monthsSinceBooster) {
    case 1:   return 0.328
    case 2:   return 0.450
    case 2.5: return 0.543
    case 6:
    default: return 0.85
  }
})()
function readCounty() {
  return localStorage.getItem(storageKeyForCounty) || 'Santa Clara County'
}
const infectiousnessUnderNormalHumidity = 0.47
const totalCloselyInteractedPeopleInsideASpace = 5
function calcPotentialInfectiousPeople(totalPeopleSharingSpace: number) {
  if (totalPeopleSharingSpace > totalCloselyInteractedPeopleInsideASpace) {
    return (totalPeopleSharingSpace - totalCloselyInteractedPeopleInsideASpace) * infectiousnessUnderNormalHumidity + totalCloselyInteractedPeopleInsideASpace
  } else {
    return totalPeopleSharingSpace
  }
}
function formatDayToHumanTimeUnit(days: number): string {
  if (days === 1) {
    return '1 day'
  } else if (days < 7) {
    return `${days} days`
  } else if (days === 7) {
    return '1 week'
  } else if (days < 30) {
    return `${(days/7.0).toFixed(1)} weeks`
  } else if (days === 30) {
    return '1 month'
  } else if (days > 365) {
    return `${(days/365.0).toFixed(1)} years`
  } else {
    return `${(days/30.0).toFixed(1)} months`
  }
}
function App() {
  const [totalPeopleSharingSpace, setTotalPeopleSharingSpace] = useState(20.0)
  const [last7DaysActiveCovidCasesInCounty, setActiveCovidCasesInCounty] = useState(1000.0)
  const [populationOfCounty, setPopulationOfCounty] = useState(1928000.0)
  const [totalEventsAttendedPerMonth, setTotalEventsAttendedPerMonth] = useState(8.0)
  const [county, setCounty] = useState(readCounty())
  const [percentageActiveCasesPerPopulation, setPercentageActiveCasesPerPopulation] = useState(-1)
  const totalEventsAttendedPerWeekString = totalEventsAttendedPerMonth === 30 ? 7 : `${Math.floor(totalEventsAttendedPerMonth / 30 * 7)}-${Math.ceil(totalEventsAttendedPerMonth / 30 * 7)}`

  // const chanceOfCovidExposure = 100.0 * (1 - Math.pow(1 - (2 * last7DaysActiveCovidCasesInCounty / populationOfCounty), totalPeopleSharingSpace * totalEventsAttendedPerMonth))
  const chanceOfCovidInfection = 100.0 * (1 - Math.pow(1 - percentageActiveCasesPerPopulation * chanceOfCovidInfectionWithBooster, calcPotentialInfectiousPeople(totalPeopleSharingSpace) * totalEventsAttendedPerMonth))
  const annualDangerousThresholdChanceOfCovidInfection = 0.0871653
  const monthlyDangerousThresholdChanceOfCovidInfection = 0.00666249816
  const daysUntilUnsafeThreshold = Math.log(1 - annualDangerousThresholdChanceOfCovidInfection) / Math.log(1 - percentageActiveCasesPerPopulation * chanceOfCovidInfectionWithBooster) / (calcPotentialInfectiousPeople(totalPeopleSharingSpace) * totalEventsAttendedPerMonth / 30)
  const recommendedTotalEventsAttendedPerMonth = Math.log(1 - monthlyDangerousThresholdChanceOfCovidInfection) / Math.log(1 - percentageActiveCasesPerPopulation * chanceOfCovidInfectionWithBooster) / calcPotentialInfectiousPeople(totalPeopleSharingSpace)

  useEffect(() => {
    fetch(`https://data.cdc.gov/resource/8396-v7yb.json?county_name=${county}&$where=report_date%20%3E=%20%27${getTodayString()}%27`)
      .then((response) => response.json())
      .then((data: CountyCaseDataResponse[]) => {
        const recentData = data.sort(({cases_per_100k_7_day_count: previous}, {cases_per_100k_7_day_count: current}) => {
          return +new Date(previous) - +new Date(current)
        })[data.length - 1]
        if (recentData && parseFloat(recentData.cases_per_100k_7_day_count)) {
          setPercentageActiveCasesPerPopulation(2.0 * parseFloat(recentData.cases_per_100k_7_day_count)/100000.0)
        } else {
          // Some counties do not have enough cases for proper data e.g. 'suppressed'
          setPercentageActiveCasesPerPopulation(-1.0)
        }
      })
  }, [county])

  return (
    <Stack direction='column' justifyContent='space-between'>
      <CssBaseline/>
      <Container style={{
        margin: '2rem auto',
        marginBottom: '6rem',
        maxWidth: '40rem',
      }}>
        <Box sx={{
          marginBottom: '2rem',
        }}>
          <Typography variant='h4' gutterBottom>
            Will I get COVID eating inside a restaurant?
          </Typography>
          <Box style={{
            marginBottom: '3em',
          }}>
          <Typography variant='body1'>
            How often can I eat inside a restaurant with friends comfortably knowing the chance of getting COVID within one year is low?
          </Typography>
          </Box>
        </Box>
        <Grid
          container
          columns={1}
          direction='column'
          rowSpacing={4}
          wrap='nowrap'
        >
          <Autocomplete
            value={county}
            options={counties}
            onChange={(event, newValue) => {
              if (newValue) {
                localStorage.setItem(storageKeyForCounty, String(newValue))
                setCounty(String(newValue))
              }
            }}
            autoHighlight
            renderInput={(params) =>
              <FormInputField
                // @ts-ignore: Type ...AutocompleteRenderInputParams is not assignable to type React.ReactNode
                {...params}
                label='Choose county of restaurant or event'
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
              />}
          />
          <Grid container
                justifyContent='space-between'
                rowSpacing='0'
                spacing='2rem'
          >
            <Grid item flexGrow='1'>
              <FormInputField id='totalPeopleSharingSpace'
                              label='Number of people in the same space'
                              value={totalPeopleSharingSpace}
                              onChange={registerSetter(setTotalPeopleSharingSpace)}
                              inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
              />
            </Grid>
            {
              false &&
              <>
                <FormInputField id='last7DaysActiveCovidCasesInCounty'
                                label='Last 7 days total active COVID cases in the county'
                                value={last7DaysActiveCovidCasesInCounty}
                                onChange={registerSetter(setActiveCovidCasesInCounty)}
                                sx={{
                                  display: 'none'
                                }}
                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />
                <FormInputField id='populationOfCounty'
                                label='Population of the county'
                                value={populationOfCounty}
                                onChange={registerSetter(setPopulationOfCounty)}
                                sx={{
                                  display: 'none'
                                }}
                                inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                />
              </>
            }
            <Grid item flexGrow='1'>
              <FormInputField id='totalEventsAttendedPerMonth'
                              label='Number of restaurants you eat at per month'
                              value={totalEventsAttendedPerMonth}
                              onChange={registerSetter(setTotalEventsAttendedPerMonth)}
                              inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
              />
            </Grid>
          </Grid>
          {percentageActiveCasesPerPopulation >= 0
            ? <h3>
              You can safely <mark>eat inside restaurants up to {Math.floor(recommendedTotalEventsAttendedPerMonth)} times a
              month</mark> for a year comfortably knowing the chance of getting COVID is less than the chance as getting into
              a car accident.
            </h3>
            : <h3>Not enough case data to determine number of times you can eat safely inside a restaurant per month.</h3>
          }
          <p>
            How many days/weeks/months can I eat inside a restaurant with friends until I am at an uncomfortable
            risk of getting infected by COVID e.g. above the chance of getting into a car accident?
          </p>
          {percentageActiveCasesPerPopulation >= 0
            ? <h3>
              In less than <mark>{formatDayToHumanTimeUnit(daysUntilUnsafeThreshold)} of eating inside a
              restaurant {totalEventsAttendedPerWeekString} times per week</mark>, the chance you've been infected by
              COVID is above the chance as getting into a car accident. Ideally this time period should be as close to
              one year as possible or larger. If smaller than a year, it means you have a higher chance of getting COVID
              than getting into a car accident.
            </h3>
            : <h3>Not enough case data to determine number of weeks</h3>
          }
          {/*{percentageActiveCasesPerPopulation >= 0*/}
          {/*  ? <p>*/}
          {/*    Reference calculation: {chanceOfCovidInfection.toFixed(2)}% chance of getting infected by a person with COVID in a month due to*/}
          {/*    eating inside restaurants*/}
          {/*  </p>*/}
          {/*  : <h3>Not enough case data to determine chance of getting infected to a person with COVID in a month</h3>*/}
          {/*}*/}
          <p style={{
            fontStyle: 'italic',
          }}>
            WIP: Is it safe for me to eat inside a restaurant with friends once a month and be comfortable with a low risk of getting COVID for one year?
          </p>
          <p style={{
            fontStyle: 'italic',
          }}>
            WIP: When will it be safe for me to eat inside a restaurant?
          </p>
          <p>
            We would love to know your <Link href='https://forms.gle/7rYbpXzyQnPf1oMw8' target='_blank' rel='noopener noreferrer'>feedback!</Link>
          </p>
          <h3 style={{
            fontWeight: 'bold',
            marginBottom: '0',
          }}>
            Motivation and Context
          </h3>
          <p>
            Built as a rule of thumb to help myself and friends with social decisions during the
            pandemic. Active COVID case data is updated once a day from U.S. CDC. Not intended to be a precise or
            scientific measure as there are many additional factors like transmission rate and protections for people at
            an event or restaurant. Being exposed does not guarantee you'll get COVID, however, there is minimal
            protection if someone with COVID is unmasked eating face to face with you with current vaccine
            effectiveness. Calculations assume >= 6 months after booster shot. Assumes comfortable humidity at
            restaurants/events (non-muggy places).
          </p>
          <Typography variant='body1'>
            While Omicron variant symptoms are less severe and cause less hospitalizations than the now defunct Delta
            variant, my personal level of risk prefers for myself to avoid getting COVID for a year while eating out
            with friends and meeting family who are more susceptible to COVID. For folks who may not mind the impact of
            a bout of COVID sickness, you can eat out 3-5 times what this tool says and likely avoid a re-infection.
          </Typography>
          <h3 style={{
            fontWeight: 'bold',
            marginBottom: '0',
          }}>
            Data and Sources
          </h3>
          <p>
            WIP
          </p>
        </Grid>
      </Container>
      <Container style={{
        textAlign: 'center',
        fontSize: '0.8rem',
      }}>
        <div style={{
          margin: '1rem'
        }}>
          Made with <span role="img" aria-label="red heart">❤️</span>️ in San Francisco
        </div>
        <div style={{
          marginBottom: '2rem'
        }}>
          Written by Thomas Lee
        </div>
      </Container>
    </Stack>
  )
}
export default App
